<!--
 * @Author: zhai
 * @Description: 其它信息
-->
<template>
    <div class="details-module">
        <Residences :data="residences" class="mb-20"/>
        <Language :data="language" class="mb-20"/>
        <Professional :data="professional" class="mb-20"/>
        <Speciality :data="specialityQuestionsOptions" class="mb-20"/>
        <!-- <Talent :data="talentQuestionsOptions"/> -->
    </div>
</template>

<script>
import Language from "./C/Language.vue"
import Professional from "./C/Professional.vue"
import Residences from "./C/Residences.vue"
import Speciality from "./C/Speciality.vue"
// import Talent from "./C/Talent.vue"
export default {
    name: "Other",
    components: {
        Language,
        Professional,
        Residences,
        Speciality,
        // Talent
    },
    props: {
        residences: {
            type: Array,
            default: () => {
                return [];
            },
        },
        language: {
            type: Array,
            default: () => {
                return [];
            },
        },
        professional: {
            type: Array,
            default: () => {
                return [];
            },
        },
        specialityQuestionsOptions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        talentQuestionsOptions: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {

        }
    },
    mounted() {

    },
}
</script>

<style lang="less" scoped>

</style>
