<!--
 * @Author: filename
 * @Description: 学历及资格证书
-->

<template>
    <div class="details-module mt-10" v-if="educationBackground">
        <Container title="主申请人大学及以上的【所有】学历及资格证书" padding="15px">
            <!-- 学历 -->
            <Education :data="educationBackground.education" />
            <!-- 资格证书 -->
            <Qualifications v-if="educationBackground.qualifications" :data="educationBackground.qualifications" />
            <!-- 学历证书姓名及出生日期正确性 -->
            <EducationQuestions v-if="educationBackground.education_questions" :data="educationBackground.education_questions" />
        </Container>
    </div>
</template>

<script>
import Education from './C/Education.vue'
import Qualifications from './C/Qualifications.vue'
import EducationQuestions from './C/EducationQuestions.vue'
export default {
    props: {
        educationBackground: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        Education,
        Qualifications,
        EducationQuestions
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="less" scoped>

</style>
