<!--
 * @Author: zhai
 * @Description: 居住信息
-->
<template>
    <div>
        <Container title="居住信息" padding="15px">
            <p class="mt-10 mb-20">请按日期顺序列出你自出生后曾居住12个月及以上的所有国家或地区，包括现居、海外居住信息（如填写了海外工作经历请对应填写符合条件的信息）</p>
            <el-table
                :data="data"
                border
                style="width: 100%">
                <el-table-column
                    prop="country"
                    label="国家"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="start_time"
                    label="由（年/月）"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="end_time"
                    label="至（年/月）"
                    align="center"
                >
                </el-table-column>
            </el-table>
        </Container>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {

        }
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>

</style>
</style>
