<!--
 * @Author: zhai
 * @Description: 语言能力
-->
<template>
    <div>
        <Container title="语言能力" padding="15px">
            <div v-for="(item,index) in data" :key="index">
                <span class="mt-20">{{ item.option }}</span><span v-if="item.remark" class="mt-20" style="color: red"> - {{ item.remark }}</span>
            </div>
        </Container>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {

        }
    },
    mounted() {

    },
}
</script>

<style lang="less" scoped>

</style>
