<!--
 * @Author: zhai
 * @Description: 辅助资料
-->
<template>
    <div>
        <Container title="辅助资料" padding="15px">
            <div v-for="(item,index) in data" :key="index">
                <p class="mt-20">{{ item.question }}</p>
                <p class="mt-10">{{ item.option }}</p>
                <el-divider v-if="index !== data.length - 1"></el-divider>
            </div>
        </Container>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            
        }
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>

</style>
