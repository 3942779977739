<!--
 * @Author: filename
 * @Description: 学历证书姓名及出生日期正确性
-->
<template>
    <div>
        <div class="sub-title">{{ data.title }}</div>
        <div class="info">
            <div v-for="(item, i) in data.questions_options" :key="i">
                <p>{{ item.question }}</p>
                <p class="mt-10">{{ item.option }}</p>
                <el-divider v-if="i !== data.questions_options.length - 1"></el-divider>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {

        }
    },
}
</script>

<style lang="less" scoped>
.info {
    padding: 10px 20px;
}
</style>
