<!--
 * @Author: filename
 * @Description: 学历/资格证书 - 资格证书
-->

<template>
    <div>
        <div  v-for="(item, i) in data" :key="i">
            <div class="sub-title">资格证书{{ i+1 }}</div>
            <div class="info">
                <el-row>
                    <el-col :span="12">
                        <span>证书名称：</span>
                        <span>{{ item.name }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>颁授机构：</span>
                        <span>{{ item.organization }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>颁授年份：</span>
                        <span>{{ item.issued_at }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>颁授城市：</span>
                        <span>{{ item.issued_address }}</span>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.info {
    padding: 10px 20px;
}
</style>
