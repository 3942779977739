<!--
 * @Author: filename
 * @Description: 学历/资格证书 - 学历信息
-->

<template>
    <div>
        <div v-for="(item, i) in data" :key="i">
            <div class="sub-title">
                学历
                <template v-if="item.title">
                    （<span style="color: red;">{{item.title}}</span>）
                </template>
            </div>
            <div class="info">
                <el-row>
                    <el-col :span="12">
                        <span>学校全称：</span>
                        <span>{{ item.college }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>授课形式：</span>
                        <span>{{ item.type }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>入学年月：</span>
                        <span>{{ item.admission_time }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>毕业年月：</span>
                        <span>
                            {{ item.graduate_time }}
                            <template v-if="isReading(item.graduate_time)">
                                <b style="color: red;">(在读)</b>
                            </template>
                        </span>
                    </el-col>
                    <el-col :span="12">
                        <span>专业：</span>
                        <span>{{ item.professional }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>学位：</span>
                        <span>{{ item.background }}<template v-if="item.degree_type && !item.background.includes('无学位')">({{item.degree_type}})</template></span>
                    </el-col>
                    <el-col :span="12">
                        <span>上课地点：</span>
                        <span class="red">{{ item.school_address }}</span>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        isReading(time) {
            if (time) {
                const years = time.split('年')[0]
                const month = time.split('年')[1].split('月')[0]
                return this.$dayjs(`${years}-${month}`) > this.$dayjs(new Date())
            }
            return ''
        }
    }
}
</script>

<style lang="less" scoped>
.info {
    padding: 10px 20px;
}
</style>
