<!--
 * @Author: zhai
 * @Description: 基本资料
-->
<template>
    <div class="details-module">
        <Container title="基本资料">
            <div v-for="(item,index) in basics" :key="index">
                <div class="sub-title">{{ item.title }}</div>
                <div v-for="(ite,ind) in item.questions_options" :key="ind" class="pl-15 pr-15 mb-30">
                    <p class="mt-20">{{ ite.question }}</p>
                    <p class="mt-10">{{ ite.option }}</p>
                    <el-divider v-if="ind !== item.questions_options.length - 1"></el-divider>
                </div>
            </div>
        </Container>
    </div>
</template>

<script>
export default {
    props: {
        basics: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {

        }
    },
}
</script>

<style lang="less" scoped>

</style>
