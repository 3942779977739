<template>
  <div class="details-module">
    <Container title="工作经验" padding="15px">
      <div v-for="(item, index) in work.history" :key="index" class="mb-20">
        <div class="sub-title">
          工作<span v-if="index === 0 || index === work.history.length - 1">(<span style="color: red">{{ item.title
          }}</span>)</span>
        </div>
        <div class="pl-15 pr-15">
          <el-row class="clu">
            <el-col :span="12">
              <span>用人单位: </span>
              <span>{{ item.company_name }}</span>
            </el-col>
            <el-col :span="12">
              <span>用人单位性质: </span>
              <span>{{ item.industry }}</span>
            </el-col>
          </el-row>
          <el-row class="clu">
            <el-col :span="12">
              <span>用人单位介绍: </span>
              <span>{{ item.company_introduce }}</span>
            </el-col>
            <el-col :span="12">
              <span>用人单位官网: </span>
              <span>{{ item.company_website }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span>工作地点:</span>
              <span>{{ item.location }}</span>
            </el-col>
            <el-col :span="12">
              <span>职位:</span>
              <span>{{ item.position }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span>入职年月:</span>
              <span>{{ item.entry_time }}</span>
            </el-col>
            <el-col :span="12">
              <span>离职年月:</span>
              <span>{{ item.departure_time }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span>证明文件:</span>
              <span>{{ item.work_certificate }}</span>
            </el-col>
            <el-col :span="12">
              <span>职业性质:</span>
              <span>{{ item.duty }}</span>
            </el-col>
            <el-col :span="12">
              <span>相关工作属国际工作经验:</span>
              <span>{{
                item.international_work_experience ? "是" : "否"
              }}</span>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="12">
              <span>企业人数规模: </span>
              <span>{{ item.company_member_total }}</span>
            </el-col>
            <el-col :span="12">
              <span>行政架构层级: </span>
              <span>{{ item.administrative_level }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span>下属管理人数:</span>
              <span>{{ item.company_manage_total }}</span>
            </el-col>
            <el-col :span="12">
              <span>公司营业额/港元（近一年）:</span>
              <span>{{ item.turnover || "-" }}</span>
            </el-col>
            <!-- <el-col :span="12">
                            <span>重要项目负责人:</span>
                            <span>{{ item.master_of_important_project }}</span>
                        </el-col> -->
          </el-row>
          <el-row>
            <el-col :span="12">
              <span>职位水平类别:</span>
              <span>{{ item.professional_level }}</span>
            </el-col>
            <el-col :span="12">
              <span>公司业务性质/范畴/所属行业:</span>
              <span>{{ item.business_nature || "-" }}</span>
            </el-col>
            <el-col :span="12">
              <span>高管证明文件:</span>
              <span>{{ item.executives_certificate }}</span>
            </el-col>
          </el-row>
          <el-row v-if="item.professional_level === '高级经理人'">
            <el-col :span="12">
              <span>高管在职时间:</span>
              <div>
                {{ item.top_manager_date }}
                <span style="padding-left: 10px">共<span style="color: red">{{
                  item.top_manager_date_long
                }}</span>个月</span>
              </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row class="clu">
            <el-col :span="24">
              <span>工作职责: </span>
              <span v-html="item.work_duty
                ? item.work_duty.replace(
                  /\n/gm,
                  `<br style='display: block;
  content: close-quote;'>`
                )
                : ''
                "></span>
            </el-col>
          </el-row>
          <el-row class="clu">
            <el-col :span="24">
              <span>工作亮点:</span>
              <span v-html="item.highlights
                ? item.highlights.replace(
                  /\n/gm,
                  `<br style='display: block;
  content: close-quote;'>`
                )
                : ''
                "></span>
            </el-col>
          </el-row>
          <el-row v-for="(pitem, pindex) in item.projects" :key="pindex" class="clu">
            <el-col :span="24">
              <span>项目介绍1:</span>
              <div class="projectc">
                <div class="yflex">
                  <div>
                    <span class="label">项目名称:</span><span>{{ pitem.project_name }}</span>
                  </div>
                  <div>
                    <span class="label">项目时间:</span><span>{{ pitem.project_time.join('-') }}</span>
                  </div>
                </div>
                <div class="project-hd">
                  <div class="label">项目业绩/亮点:</div>
                  <div>
                    {{ pitem.project_highlights }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="clu">
            <el-col :span="12">
              <span>文件附件:</span>
              <div class="file-con">
                <div v-for="(ite, ind) in item.files" :key="ind" class="mr-20">
                  {{ ite.fileName
                  }}<el-button @click="toView(ite)" type="text" style="margin-left: 20px">查看</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="Object.keys(work).length > 0">
        <div class="sub-title">{{ work.work_questions.title }}</div>
        <div v-for="(item, index) in work.work_questions.questions_options" :key="index" class="pl-15 pr-15">
          <p class="mt-20">{{ item.question }}</p>
          <p class="mt-10">{{ item.option }}</p>
          <el-divider v-if="index !== work.work_questions.questions_options.length - 1"></el-divider>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
export default {
  props: {
    work: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    async toView(item) {
      if (this.$utils.isImageType(item.fileUrl)) {
        window.open(this.$utils.toOss(item.fileUrl), "_blank");
      } else {
        window.open(
          await this.$utils.previewUrl(item.fileUrl, item.fileName),
          "_blank"
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.file-con {
  display: flex;
  flex-direction: column;
}

.clu .el-col {
  align-items: flex-start !important;
}

.projectc {
  border-radius: 8px;
  background: #fafafa;
  width: 100%;
  padding: 20px;
  margin-bottom: 12px;
  color: #606266;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  .yflex {
    display: flex;
    justify-content: space-between;
  }

  .label {
    display: inline-block;
    width: 105px;
  }

  .project-hd {
    margin-top: 12px;
    display: flex;

    .label {
      min-width: 105px;
    }
  }
}
</style>
